import * as React from "react";
import { withStores } from "stores";
import { PageHeadingProps } from "../typings";
import { EGDSHeading } from "@egds/react-core/text";
import { useLocalization } from "@shared-ui/localization-context";
import { observer } from "mobx-react";
import { EGDSSpacing } from "@egds/react-core/spacing";

const PageHeadingWizard = withStores(
  "compositionStore",
  "flexModuleModelStore"
)(
  observer((props: PageHeadingProps) => {
    const { formatText } = useLocalization();
    const { compositionStore, templateComponent, context } = props;
    const { pageHeading } = compositionStore;
    const { config } = templateComponent;
    const headingSize = context.deviceInformation && context.deviceInformation.mobile ? 2 : 1;

    if (!pageHeading) {
      return null;
    }

    if (config.view === "hidden") {
      return (
        <EGDSHeading tag="h2" className="sr-only">
          {formatText("wizard.a11y.header")}
        </EGDSHeading>
      );
    }

    return (
      <EGDSSpacing padding={{ blockstart: "one", blockend: "four" }}>
        <div>
          <EGDSHeading tag="h1" size={headingSize}>
            {pageHeading}
          </EGDSHeading>
        </div>
      </EGDSSpacing>
    );
  })
);

export default PageHeadingWizard;
